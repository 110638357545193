import { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import { useZoomPanContext } from "react-simple-maps";
import { Geography, useMapContext } from "react-simple-maps";

export default function AnimatedGeography({
  geo,
  isVisited,
  handleClick,
  setTooltipContent,
  unVisitedColor = "#D6D6DA",
  // visitedColor = "#ff6347"
}) {
    // Функция для генерации случайного цвета
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
  
  const [circleCoords, setCircleCoords] = useState({ cx: 0, cy: 0 });
  const [isAnimatedFinished, setIsAnimatedFinished] = useState(isVisited);
  const [visitedColor, setVisitedColor] = useState(getRandomColor());
  const ctx = useZoomPanContext();
  const { projection } = useMapContext();
  const circleRef = useRef(null);
  // const pathRef= useRef(null);


  const convertLatLngToSvgCoords = (lat, lng) => {
    const [x, y] = projection([lng, lat]);
    return { x, y };
  };

  const getSvgCoordinates = (event) => {
    const svg = event.target.ownerSVGElement || event.target;
    const pt = svg.createSVGPoint();
    // console.log("ctx", ctx);
    pt.x = event.clientX;
    pt.y = event.clientY;

    let svgCoords = pt.matrixTransform(svg.getScreenCTM().inverse());

    svgCoords.x = (svgCoords.x - ctx.x) / ctx.k;
    svgCoords.y = (svgCoords.y - ctx.y) / ctx.k;
    return svgCoords;
  };

  // Функция для расчета радиуса от точки клика до самых крайних точек страны
  const getRadiusFromClick = (clickCoords, geo) => {
    let maxDistance = 0;

    // Проверяем, является ли тип Polygon или MultiPolygon
    if (geo.geometry.type === "Polygon") {
      geo.geometry.coordinates.forEach((polygon) => {
        polygon.forEach((coordinate) => {
          if (Array.isArray(coordinate) && coordinate.length === 2) {
            const [lng, lat] = coordinate;

            // Преобразуем широту и долготу в координаты SVG
            const { x, y } = convertLatLngToSvgCoords(lat, lng);

            // Вычисляем расстояние от точки клика до текущей точки
            const distance = Math.sqrt(
              Math.pow(x - clickCoords.cx, 2) + Math.pow(y - clickCoords.cy, 2)
            );

            // Обновляем максимальное расстояние
            if (distance > maxDistance) {
              maxDistance = distance;
            }
          }
        });
      });
    } else if (geo.geometry.type === "MultiPolygon") {
      geo.geometry.coordinates.forEach((multiPolygon) => {
        multiPolygon.forEach((polygon) => {
          polygon.forEach((coordinate) => {
            if (Array.isArray(coordinate) && coordinate.length === 2) {
              const [lng, lat] = coordinate;

              // Преобразуем широту и долготу в координаты SVG
              const { x, y } = convertLatLngToSvgCoords(lat, lng);

              // Вычисляем расстояние от точки клика до текущей точки
              const distance = Math.sqrt(
                Math.pow(x - clickCoords.cx, 2) +
                  Math.pow(y - clickCoords.cy, 2)
              );

              // Обновляем максимальное расстояние
              if (distance > maxDistance) {
                maxDistance = distance;
              }
            }
          });
        });
      });
    }

    // Возвращаем максимальное расстояние (радиус)
    return Math.max(maxDistance, 10); // Минимум 10 для небольших стран
  };

  const handleGeoClick = (event) => {
    if (!isVisited) {
      const svgCoords = getSvgCoordinates(event);
      setCircleCoords({ cx: svgCoords.x, cy: svgCoords.y });
      setVisitedColor(getRandomColor());
      handleClick(geo);
    }
  };

  
  useEffect(() => {
    if (circleCoords.cx !== 0 && circleCoords.cy !== 0) {
      const optimalRadius = getRadiusFromClick(circleCoords, geo);
      // console.log("optimalRadius", optimalRadius);
      gsap.fromTo(
        circleRef.current,
        { attr: { cx: circleCoords.cx, cy: circleCoords.cy, r: 1 } },
        {
          attr: { r: optimalRadius },
          duration: 2,
          ease: "power2.out",
          onComplete: () => {
            setIsAnimatedFinished(true);
          },
        }
      );
    }
  }, [circleCoords, geo]);

  return (
    <g>
      <defs>
        <mask id={`mask-${geo.id}`}>
          <circle ref={circleRef} cx={0} cy={0} r="0" fill="white" />
        </mask>
      </defs>

      <Geography
        className="hover-country-class"
        key={geo.rsmKey}
        // ref={pathRef}
        // d={geo.svgPath}
        geography={geo}
        onMouseEnter={() => {
          // console.log(`${geo.properties.name}`);
          setTooltipContent(`${geo.properties.name}`);
        }}
        onMouseLeave={() => {
          setTooltipContent("");
        }}
        fill={isAnimatedFinished ? visitedColor : unVisitedColor}
        stroke="#000"
        strokeWidth={isAnimatedFinished ? "0.7" : "0.1"}
        onClick={handleGeoClick}
        // outline="none"
        style={{
          default: { outline: "none" },
          hover: { strokeWidth: "1", outline: "none" },
          pressed: { outline: "none" },
        }}
      />

      <path
        d={geo.svgPath}
        fill={visitedColor}
        visibility={
          circleCoords.cx !== 0 && !isAnimatedFinished ? "visible" : "hidden"
        }
        stroke="#000"
        strokeWidth="0.7"
        mask={`url(#mask-${geo.id})`}
        // style={{
        //   cursor: "pointer",
        //   outline: "none",
        // }}
      />
    </g>
  );
}
