import React from "react";
import { ZoomableGroup, ComposableMap, Geographies } from "react-simple-maps";
import AnimatedGeography from "./AnimatedGeographyCircle";

import geoObj from "../../topojson/features.json";
// console.log("geoObj", geoObj);
// const geoUrl =
//   "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

export default function MapChart({
  setTooltipContent,
  visitedCountries,
  setVisitedCountries,
}) {
  const handleClick = (geo) => () => {
    const countryId = geo.id;
    if (!visitedCountries.includes(countryId)) {
      setVisitedCountries([...visitedCountries, countryId]);
      // console.log("visitedCountries", visitedCountries);
      // console.log("geo", geo);
    }
  };
  const isVisited = (geoId) => {
    return visitedCountries.includes(geoId);
  };



  return (

    <ComposableMap projection="geoEqualEarth" data-tip="" style={{ width: "100%", height: "auto" }}>
      <ZoomableGroup>
        <Geographies geography={geoObj}>
          {({ geographies }) =>
            geographies.map((geo) => {
              // geo.color=generateColor();
              return (
                <AnimatedGeography
                  key={geo.rsmKey}
                  geo={geo}
                  isVisited={isVisited(geo.id)}
                  handleClick={handleClick}
                  setTooltipContent={setTooltipContent}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
}

// <ComposableMap>
//   <Geographies geography={geoObj}>
//     {({ geographies }) =>
//       geographies.map((geo) => (
//         <Geography key={geo.rsmKey} geography={geo} />
//       ))
//     }
//   </Geographies>
// </ComposableMap>
