import React, { useState, useContext } from "react";
import MapComponent from "./MapComponent"



export default function MapContent() {
  return (

      <MapComponent />
  );
}
