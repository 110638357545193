import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import MapChart from "./MapChart";

export default function MapComponent() {
  const [selectCountry, setSelectCountry] = useState("");
  const [visitedCountries, setVisitedCountries] = useState(["CHN"]);

  return (
    <div>
      <MapChart setTooltipContent={setSelectCountry} visitedCountries={visitedCountries} setVisitedCountries={setVisitedCountries}/>
      <Tooltip anchorSelect=".hover-country-class"> {selectCountry}</Tooltip>
    </div>

  );
}


    // <ComposableMap>
    //   <Geographies geography={geoObj}>
    //     {({ geographies }) =>
    //       geographies.map((geo) => (
    //         <Geography key={geo.rsmKey} geography={geo} />
    //       ))
    //     }
    //   </Geographies>
    // </ComposableMap>