import { useEffect, useState } from "react";
import MapContent from "./components/Map/MapContent.jsx";


async function getUsers() {
  const baseURL = process.env.REACT_APP_API_URL || "";

  const response = await fetch(`${baseURL}/users`);

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const users = await response.json();

  return users;
}

export function App() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      setUsers(await getUsers());
    })();
  }, []);

  return (
    <MapContent />
  );
}
